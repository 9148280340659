import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import { MainLayout, PostCard, PaginationHelper, PostsRow } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Row, Col, Divider, Typography, Tag } from 'antd'
const { Title, Text } = Typography
import { Parallax } from 'rc-scroll-anim'
/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const siteTitle = data.site.siteMetadata?.title || `Joe Czubiak`
    // const posts = data.tutorials.edges
    const thoughtsPosts = data.thoughts.nodes
    const tutorialPosts = data.tutorials.nodes
    const projectPosts = data.projects.nodes

    return (
        <>
            <MetaData location={location} />
            <MainLayout isHome={true}>

                <Title className={`homeTitle`} level={2}>
                    <Parallax
                        animation={{ x: 0, opacity: 1, playScale: [0, .4] }}
                        style={{ transform: `translateX(-10px)`, margin: `10px auto`, opacity: .6 }}
                    >
                        {/*<img className={`blob`} src={`/images/blob4.svg`} alt="" />*/}
                        <img className={`blob`} src={`/images/brush.svg`} alt="" width="517" height="105" />
                        <Link to="/blog" style={{ color: `#343a40` }}>Thoughts</Link>

                    </Parallax>
                </Title>

                <PostsRow posts={thoughtsPosts} />

                <Divider />

                <Title className={`homeTitle`} level={2}>
                    <Parallax
                        animation={{ x: 0, opacity: 1, playScale: [0, .4] }}
                        style={{ transform: `translateX(-10px)`, margin: `10px auto`, opacity: .6 }}
                    >
                        <img className={`blob`} src={`/images/brush.svg`} alt="" width="517" height="105" />
                        <Link to="/tag/project" style={{ color: `#343a40` }}>Projects</Link>
                    </Parallax>
                </Title>

                <PostsRow posts={projectPosts} />

                <Divider />

                <Title className={`homeTitle`} level={2}>
                    <Parallax
                        animation={{ x: 0, opacity: 1, playScale: [0, .4] }}
                        style={{ transform: `translateX(-10px)`, margin: `10px auto`, opacity: .8 }}
                    >
                        <img className={`blob`} src={`/images/brush.svg`} alt="" width="517" height="105" />
                        <Link to="/tag/tutorial" style={{ color: `#343a40` }}>Tutorials</Link>
                    </Parallax>
                </Title>
                <PostsRow posts={tutorialPosts} />

                {/*<PaginationHelper pageContext={pageContext} />*/}

            </MainLayout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            nodes: PropTypes.object,
        }),
        thoughts: PropTypes.shape({
            nodes: PropTypes.array,
        }),
        projects: PropTypes.shape({
            nodes: PropTypes.array,
        }),
        tutorials: PropTypes.shape({
            nodes: PropTypes.array,
        }),
    }),
}

export default Index
// filter: { frontmatter: { tags: { in: ["tutorial"] } } }
export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        title
      }
    }
    tutorials: allMarkdownRemark(
        limit: 4
        filter: {
            frontmatter: {collectionType: {eq: "post"}, public: {eq: true}, tags: {elemMatch: {slug: {eq: "tutorial"}}}}
        }
        sort: { fields: [frontmatter___published_at], order: DESC }
    ) {
        nodes {
            ...MultiplePostFields
        }
    }

    thoughts: allMarkdownRemark(
      limit: 4
      filter: {frontmatter: {collectionType: {eq: "post"}, public: {eq: true}, tags: {elemMatch: {slug: {nin: ["tutorial", "project"]}}}}}
      sort: { fields: [frontmatter___published_at], order: DESC }
    ) {
        nodes {
            ...MultiplePostFields
        }
    }

    projects: allMarkdownRemark(
      limit: 4
      filter: {frontmatter: {collectionType: {eq: "post"}, public: {eq: true}, tags: {elemMatch: {slug: {eq: "project"}}}}}
      sort: { fields: [frontmatter___published_at], order: DESC }
    ) {
        nodes {
            ...MultiplePostFields
        }
    }
    file(relativePath: { eq: "images/corgi.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
 `
